import ApiClient, { parseRequestData } from "../utilities/http";
import {Endpoints} from "../utilities/endpoints";
import {endSubmitting, isSubmitting} from "./loadActions";
import {get} from "lodash";
import {IActivity, IActivityType} from "../models/IAsset";
import * as actionTypes from "../actionsTypes";
import {toastError, toastSuccess} from "./formInputAction";

export const getActivityTypes = () => {
    return (dispatch: any) => {
        dispatch(isSubmitting({
            submitButton: true,
        }));
        ApiClient.get(Endpoints.ACTIVITY_TYPES)
            .then(function (response){
                dispatch(endSubmitting({
                    submitButton: false,
                }));
                if(get(response, 'statusText') == 'OK'){
                    const types: IActivityType[] = get(response, 'data.activity_types');
                    if(types){
                        dispatch({
                            type: actionTypes.GET_ACTIVITY_TYPES,
                            payload: [...types]
                        })
                        return;
                    }
                }
                toastError({}, 'Error Fetching Activity Types')
            })
            .catch(function (error){
                toastError(error, 'Error Fetching Activity Types')
            })
    }
}

export const requestActivity = (data: any, targetBtn: string) => {
    return (dispatch: any) => {
        dispatch(isSubmitting({
            submitButton: true,
            target: targetBtn
        }));

        ApiClient.post(Endpoints.REQUEST_ACTIVITY + '/' + data['asset_id'] + '/' + data['activity_type_id'],
            parseRequestData(data, 'multipart'), {
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(function (response){
                dispatch(endSubmitting({
                    submitButton: false,
                }));

                if(get(response, 'statusText') == 'OK'){
                    const activity: IActivity = get(response, 'data.activity');
                    if(activity){
                        dispatch({
                            type: actionTypes.REQUEST_ACTIVITY_CREATION,
                            payload: {...activity}
                        })
                        dispatch(toastSuccess('Activity Request', 'Activity Request is successfully placed'))
                        setTimeout(() => dispatch({
                            type: actionTypes.UNSET_PROBED_ASSET
                        }), 2000)
                        return;
                    }
                }
                dispatch(toastError({}, 'Error Requesting Activity'))
                dispatch(endSubmitting({
                    submitButton: false,
                }));
            })
            .catch(function(error){
                dispatch(toastError(error, 'Error Requesting Activity'))
                dispatch(endSubmitting({
                    submitButton: false,
                }));
            })
    }
}

export const getMyActivities = (count = false, all = false, justMine = false) => {
    return (dispatch: any) => {
        const target = all || justMine
        let targetStr = all ? 'all' : '';
        if(targetStr.length > 0) targetStr += '-'
        targetStr += justMine ? 'mine' : '';

        ApiClient.get(Endpoints.ACTIVITY + (count ? '/count' : '') + (target ? ('/' + targetStr) : ''))
            .then(function (response){
                if(count){
                    const activityCount = get(response, 'data.my_activity_count');
                    if(activityCount){
                        dispatch({
                            type: actionTypes.RECEIVED_MY_ACTIVITY_COUNT,
                            payload: activityCount
                        })
                    }
                } else {
                    const activities = get(response, 'data.my_activities');
                    if(activities){
                        dispatch({
                            type: actionTypes.RECEIVED_MY_ACTIVITIES,
                            payload: [...activities]
                        })
                    }
                }

            })
    };
}

export const modifyActivity = (data: any) => {
    return (dispatch: any) => {
        dispatch(isSubmitting({
            submitButton: true,
        }));
        console.log(data)
        ApiClient.put(`${Endpoints.ACTIVITY}/${data.id}`, data)
            .then(function (response){
                dispatch({
                    type: actionTypes.SET_CURRENT_ACTIVITY,
                    payload: get(response, 'data.activity')
                })
            }).catch(function (err){
                dispatch(toastError(err, 'Error Updating Activity'))
            }).finally(function (){
                dispatch(endSubmitting({
                    submitButton: false
                }))
            })
    }
}

export const setCurrentActivity = (activity: IActivity) => {
    return (dispatch: any) => {
        dispatch({
            type: actionTypes.SET_CURRENT_ACTIVITY,
            payload: activity
        })
    }
}

export const unSetCurrentActivity = () => {
    return (dispatch: any) => {
        dispatch({
            type: actionTypes.UNSET_CURRENT_ACTIVITY,
        })
    }
}
