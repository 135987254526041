import axios, {AxiosHeaders, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import Config from './Config';
import cookie from './cookie';
import {Constants} from './constants';
import {Utility} from "./utility";
import { isArray, get } from 'lodash';
import {Endpoints} from "./endpoints";


const createHeaders = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const session = cookie.get(Constants.ACCESS_KEY);
    const csrftoken = cookie.get(Constants.CSRF_KEY);

    config.headers.set('Accept', 'application/json');

    if(!config.headers.get('Content-Type'))
        config.headers.set('Content-Type', 'application/json;charset=UTF-8');

    if (session) config.headers.set('Authorization', `Bearer ${session}`);
    if (csrftoken) config.headers.set('requesttoken', csrftoken);
    return config;
};

const http: AxiosInstance = axios.create({
    baseURL: Config.api,
    headers: createHeaders({headers: new AxiosHeaders()}).headers,
    transformResponse: [
        (data) => {
            const isoDateRegex = /^\d{4}-\d{2}-\d{2}([T\s]\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/;
            const jsonData = JSON.parse(data);
            const convertDates = (obj: any) => {
                if (obj && typeof obj === 'object') {
                    for (const key in obj) {
                        if (Object.prototype.hasOwnProperty.call(obj, key)) {
                            const value = obj[key];
                            if (typeof value === 'string' && isoDateRegex.test(value)) {
                                obj[key] = new Date(value);
                            } else if (typeof value === 'object') {
                                convertDates(value);
                            }
                        }
                    }
                }
                return obj;
            };
            return convertDates(jsonData);
        }
    ]
});

const isUrlEncoded = (url?: string): boolean => {
    return [
        Endpoints.LOG_IN,
    ].includes(url as Endpoints);
};

// Request interceptor for API calls
http.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    config = createHeaders(config);
    if(config.baseURL?.includes('{tenant}')){
        if (config.method === 'post') {
            const { tenant } = config.data || {};

            if(tenant){
                config.baseURL = config.baseURL.replace('{tenant}', tenant);
                cookie.remove('tenant')
                cookie.put('tenant', tenant)
            }
        }
    }
    config.headers.set('Content-Type', isUrlEncoded(config.url) ? 'application/x-www-form-urlencoded' : config.headers.get('Content-Type'));
    return config;
});

// Response interceptor for API calls
http.interceptors.response.use((response: AxiosResponse) => {
    return response
}, async function (error) {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
        cookie.remove(Constants.ACCESS_KEY)
        cookie.remove(Constants.REFRESH_KEY)
    }

    if (error.response && error.response.status === 500) {
        const errMsg: string = get(error.response, 'data.message');
        if(errMsg.includes('Tenant could not be identified on domain')){
            cookie.remove('tenant')
            window.location.replace('/tenant-not-found')
        }
    }

    if (get(error.response, 'status') === 403 && get(error.response, 'config.url') !== "login/confirm" && !originalRequest._retry) {
        const access_token = await Utility.refreshToken();
        if(!access_token){
            cookie.remove(Constants.ACCESS_KEY)
            cookie.remove(Constants.REFRESH_KEY)
        }
        originalRequest._retry = true;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        return http(originalRequest);
    }

    return Promise.reject(error);
});

// Create a new Axios instance for multipart form data requests
const multipartHttp: AxiosInstance = axios.create({
    baseURL: Config.api,
});

// Request interceptor for multipart form data requests
multipartHttp.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    config = createHeaders(config);
    config.headers['Content-Type'] = 'multipart/form-data';
    return config;
});

export {multipartHttp};

export const parseRequestData = (data: any, method: string = ''): any => {
    if (isArray(data)) {
        return data.join(',');
    }

    if (method === 'multipart') {//NOTE case?
        const fd = new FormData();
        for (const key in data) {
            if(isArray(data[key])){
                data[key].forEach((file: File, index: number) => {
                    fd.append(`files[${index}]`, file);
                });
            } else {
                fd.append(key, data[key])
            }
        }

        return fd;
    }

    if (method === 'get') {//NOTE case?
        const params = new URLSearchParams();
        for (const key in data) {
            params.append(key, data[key])
        }
        return params.toString();
    }
    return data;
};

export default http;
