import React, {useEffect, useState} from 'react';
import '../assets/styles/App.css';
import AuthService from "../services/AuthService";
import { Button, Col, Container, Row, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
    Calculator,
    ClipboardList,
    ScanEye,
} from "lucide-react";
import {connect} from "react-redux";
import {getMyActivities} from "../actions/activityActions";

interface Props {
    dispatch: Function;
    activityCount?: number;
}

const Dashboard: React.FC<Props> = ({ dispatch, activityCount }) => {
    const user = AuthService.getUser();
    const navigate = useNavigate();
    const [isSheetOpen, setIsSheetOpen] = useState(false);

    const openTasks = 432;
    const totalAssets = 337;

    const openSheet = () => {
        navigate('/inquiry');
    };

    const scanInventory = () => {
        navigate('/scan_inventory');
    };

    const handleActivity = () => {
        navigate('/activity');
    };

    const closeSheet = () => {
        setIsSheetOpen(false);
    };

    const genericClickHandler = () => console.log('Execute button handler');

    const actionButtons = [
        { name: 'Inquiry', icon: ScanEye, clickHandler: openSheet, enabled: true},
        { name: 'Inventory', icon: Calculator, clickHandler: scanInventory, enabled: true },
        { name: 'Activity', icon: ClipboardList, clickHandler: handleActivity,
            enabled: true, notification_count: activityCount },
    ];
    useEffect(function (){
        dispatch(getMyActivities(true))
    })
    return (
        <Container className="flex-grow-1 py-4">
            {/* Summary Section */}
            <Row className="g-3 mb-4 d-none">
                <Col xs={6}>
                    <div className="bg-primary text-white p-3 rounded shadow-sm">
                        <p className="mb-1 small">Open Tasks</p>
                        <h2>{openTasks}</h2>
                    </div>
                </Col>
                <Col xs={6}>
                    <div className="bg-success text-white p-3 rounded shadow-sm">
                        <p className="mb-1 small">Total Assets</p>
                        <h2>{totalAssets}</h2>
                    </div>
                </Col>
            </Row>

            {/* Action Buttons */}
            <div className="bg-white rounded shadow-md p-4 mb-4">
                <h3 className="h5 mb-3">Actions</h3>
                <Row className="g-3">
                    {actionButtons.map((button) => (
                        <Col xs={6} key={button.name}>
                            <div className="position-relative">
                                <Button
                                    variant="light"
                                    disabled={!button.enabled}
                                    onClick={button.clickHandler}
                                    className="d-flex flex-column align-items-center justify-content-center py-3 shadow-sm w-100"
                                >
                                    <button.icon className="mb-2 text-primary" size={24} />
                                    <span className="small">{button.name}</span>
                                </Button>
                                {!!button.notification_count && button.notification_count > 0 && (
                                    <Badge bg="info"
                                        pill
                                        className="position-absolute top-0 start-100 translate-middle"
                                    >
                                        {button.notification_count}
                                    </Badge>
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </Container>
    );
}
const mapStateToProps = (state: any) => ({
    activityCount: state.activity.my_activity_count,
});

export default connect(mapStateToProps)(Dashboard);
