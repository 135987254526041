import * as actionTypes from '../actionsTypes';
import {IActivityState, IAssetState} from "../models/IAsset";

const initialActivityState: IActivityState = {
    activity: undefined,
    my_activities: [],
    my_activity_count: 0,
};

export const activity = (state: IActivityState = initialActivityState, action: any): IActivityState => {
    switch (action.type) {
        case actionTypes.RECEIVED_MY_ACTIVITIES:
            return {
                ...state,
                my_activities: action.payload
            };

        case actionTypes.RECEIVED_MY_ACTIVITY_COUNT:
            return {
                ...state,
                my_activity_count: action.payload
            };

        case actionTypes.SET_CURRENT_ACTIVITY:
            return {
                ...state,
                activity: action.payload
        }

        case actionTypes.UNSET_CURRENT_ACTIVITY:
            return {
                ...state,
                activity: undefined
        }

        default: {
            return state;
        }
    }
};
