import React, { useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import {Camera, X, ScanQrCode} from "lucide-react";

interface QRScannerProps {
    onResult: (decodedText: string) => void;
    scanOnload?: boolean;
    id?: string;
    compact?: boolean;
}

const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
let html5QrCode: Html5Qrcode | null = null;

const QRScanner: React.FC<QRScannerProps> = ({ onResult, compact= false, scanOnload= false, id= 'reader' }) => {
    const [isScanning, setIsScanning] = useState<boolean>(false);
    const qrCodeSuccessCallback = (decodedText: string) => {
        onResult(decodedText);
        stopScanning();
    };
    const qrCodeErrorCallback = (err: any) => {
        console.log(err)
    };
    useEffect(() => {
        if (!html5QrCode) {
            html5QrCode = new Html5Qrcode(id);

            if (scanOnload) {
                startScanning();
            }
        }

        return () => {
            stopScanning();
        };
    }, [scanOnload]);

    const startScanning = () => {
        if (html5QrCode && !isScanning) {
            html5QrCode.start(
                { facingMode: 'environment' },
                qrConfig,
                qrCodeSuccessCallback,
                qrCodeErrorCallback
            )
                .then(() => {
                    setIsScanning(true);  // Update state only after successful start
                })
                .catch((err: any) => {
                    console.error(`Unable to start scanning: ${err}`);
                });
        }
    };

    const stopScanning = () => {
        if (html5QrCode && (html5QrCode.isScanning || isScanning)) {
            html5QrCode.stop()
                .then(() => {
                    html5QrCode?.clear();
                    setIsScanning(false);
                })
                .catch((err: any) => {
                    console.warn(`Cannot stop, scanner is not running: ${err}`);
                });
        }
    };

    return (
        <>
            {compact ? ('') : (<div id={id} style={{width: '100%', zIndex: 101}}/>)}
            {isScanning ? (
                <button type="button" className={"btn btn-outline-danger " + (compact ? '' : 'mt-3')} style={compact ? {marginLeft: '10px'} : {}} onClick={stopScanning}>
                    { compact ? <X /> : 'Stop Scanning'}
                </button>
            ) : (
                <button type="button" className={"btn btn-outline-primary " + (compact ? '' : 'mt-3')} style={compact ? {marginLeft: '10px'} : {}} onClick={startScanning}>
                    { compact ? <ScanQrCode /> : 'Start Scanning'}
                </button>
            )}
        </>
    );
};

export default QRScanner;
