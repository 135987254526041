import {get} from "lodash"

import {IAuthState} from "../models/types";
import * as actionTypes from "../actionsTypes";
import ApiClient, { parseRequestData } from "../utilities/http";
import {Endpoints} from "../utilities/endpoints";
import cookie from "../utilities/cookie";
import {Constants} from "../utilities/constants";
import {toastError, toastSuccess} from "./formInputAction";
import { endCheckingLoginStatus, endSubmitting, isCheckingLoginStatus, isSubmitting } from "./loadActions";
import config from "../utilities/Config";
import { Utility } from "../utilities/utility";
import AuthService from "../services/AuthService";


export const receiveAuthData = (authData: IAuthState) => ({
    type    : actionTypes.LOG_IN,
    payload : { ...authData },
});

//TODO Erik: refactor email to username or similar
//TODO Erik: fix typos
//TODO Erik: fix camera usage
//TODO Erik: import inventory logic


export const loginIn = (email: string, password: string, platform: string) => {
    return (dispatch: any) => {
        dispatch(isSubmitting({
            submitButton: true,
        }));
        ApiClient.post(Endpoints.LOG_IN as string, parseRequestData(
            {
                login           : email,
                password        : password,
                tenant          : platform,
                clientIdentifier: config.clientIdentifier
            })).then((response) => {

                dispatch(endSubmitting({
                    submitButton: false,
                }));

            const authData: IAuthState = {
                    currentUser     : undefined,
                    isAuthenticated : false
                };
                const code = get(response, 'data.token');

                if((get(response, 'statusText') == 'OK')||(get(response,'status')===200)){
                    //not all webservers (apparentle) send an OK statusText. nginx does not
                    authData.isAuthenticated    = true;
                    if(code){
                        authData.currentUser        = get(response, 'data.user');
                        cookie.put(Constants.ACCESS_KEY, code, "Lax", true);
                        cookie.put(Constants.REFRESH_KEY, code, "Lax", true);
                        cookie.put('current_user', JSON.stringify(authData.currentUser), "Lax", true);
                    } else {
                        authData.currentUser       = AuthService.getUser()
                    }
                    dispatch(receiveAuthData(authData));
                    dispatch(endSubmitting({
                        submitButton: false,
                    }));
                    window.location.replace(config.url + 'dashboard')
                } else {
                    dispatch(toastError({}, 'Error Logging In'))
                }
                dispatch(receiveAuthData(authData));
        }).catch(err => {
            dispatch(toastError(
                err, "Login Error"
            ));

            dispatch(endSubmitting({
                submitButton: false,
            }));

            dispatch(receiveAuthData({
                currentUser     : undefined,
                isAuthenticated : false
            }))
        });
    }
};

export const checkLogin = () => {
    return (dispatch: any) =>
    {
        dispatch(isCheckingLoginStatus({
            appComponent      : true,
        }));
        ApiClient.put(Endpoints.USER_STATUS_HEART_BEAT as string, parseRequestData({status: 'online'})).then((response) => {
            dispatch(endCheckingLoginStatus({
                appComponent      : false,
            }));
            dispatch(receiveAuthData({
                currentUser     : JSON.parse(cookie.get('current_user')),
                isAuthenticated : true
            }))
        }).catch(err => {
            dispatch(endCheckingLoginStatus({
                appComponent      : false,
            }));
            dispatch(receiveAuthData({
                currentUser     : undefined,
                isAuthenticated : false
            }))
        });
    }
}

export const logout = () => {
    return (dispatch: any) => {
        const authData: IAuthState = {
            currentUser     : undefined,
            isAuthenticated : false
        };
        dispatch(receiveAuthData(authData));

        ApiClient.post(Endpoints.LOG_OUT as string).then((response) => {
            toastSuccess('Successful', "Logged out successfully")
        }).catch(err => {
            // toastError(err, "Error login out")
        }).finally(() => {
            cookie.remove(Constants.ACCESS_KEY)
            cookie.remove(Constants.REFRESH_KEY)
            cookie.remove('current_user')
            cookie.remove('tenant')
            Utility.redirectToLogin();
        })
    }
};

export const getLogoURL = () => {
    return (dispatch: any) => {
        ApiClient.get(Endpoints.LOGO_URL as string).then((response) => {
            const logoUrl = get(response, 'data.logo_url');
            dispatch({
                type: actionTypes.RECEIVE_LOGO_URL,
                payload: {logoURL: logoUrl}
            })
        })
    }
}
