import React, {useEffect, useState} from "react";
import {Button, Row, Col, Form, Badge} from "react-bootstrap";
import {IActivity} from "../models/IAsset";
import {connect} from "react-redux";
import {getMyActivities, setCurrentActivity, unSetCurrentActivity} from "../actions/activityActions";
import ActivityDetails from "../components/activity-details";
import {
    CalendarClock,
    List,
    X,
    Activity,
    Fingerprint,
    CircleDot,
    MapPin,
    User,
    Users,
    Flag,
    AlertTriangle, AlertCircle, AlertOctagon
} from "lucide-react";

interface IActivityProp {
    activities: IActivity[],
    currentAct: IActivity,
    dispatch: Function
}

const Activites: React.FC<IActivityProp> = ({activities, currentAct, dispatch}) => {
    const [isTeamView, setIsTeamView] = useState(true);
    const [showCompleted, setShowCompleted] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState<IActivity | null>(
        null
    );

    const handleShowCompleted = () => {
        if(!showCompleted){
            dispatch(getMyActivities(false, true, !isTeamView));
        } else {
            dispatch(getMyActivities(false, false, !isTeamView));
        }
        setShowCompleted(!showCompleted)
    }

    const handleTeamView = (isTeam: boolean) => {
        setShowCompleted(false);
        dispatch(getMyActivities(false, false, !isTeam));

        setIsTeamView(isTeam)
    }
    const handleStartPerforming = (activity: IActivity) => {
        // Handle the "Start Performing" action here
        console.log("Started Performing Activity:", activity);
    };

    useEffect(function (){
        dispatch(getMyActivities(false, false, !isTeamView));
    },[currentAct])

    if (currentAct) {
        return (
            <ActivityDetails
                onClose={() => dispatch(unSetCurrentActivity())}
                onStartPerforming={handleStartPerforming}
            />
        );

    }

    return (
        <div className="container py-3">
            {/* Toggle Buttons */}
            <Row className="mb-3">
                <Col xs={6}>
                    <Button
                        variant={isTeamView ? "success" : "outline-secondary"}
                        className="w-100"
                        onClick={() => handleTeamView(true)}
                        style={{padding: "0.5rem"}}
                    >
                        For my team(s)
                    </Button>
                </Col>
                <Col xs={6}>
                    <Button
                        variant={!isTeamView ? "success" : "outline-secondary"}
                        className="w-100"
                        onClick={() => handleTeamView(false)}
                        style={{padding: "0.5rem"}}
                    >
                        For me
                    </Button>
                </Col>
            </Row>

            {/* Activities Header */}
            <div className="d-flex justify-content-between align-items-center mb-1">
                <h5 className="mb-0">Activities ({activities.length})</h5>
                <Form.Check
                    type="checkbox"
                    label="Show completed"
                    checked={showCompleted}
                    onChange={() => handleShowCompleted()}
                    className="small"
                />
            </div>

            {/* Activities List */}
            <Row>
                {activities.map((activity) => (
                    <Col xs={12} className="mb-3" key={activity.activity_uid}>
                        <div
                            className="border rounded p-3 position-relative shadow-sm"
                            onClick={() => dispatch(setCurrentActivity(activity))}
                            style={{ cursor: 'pointer', backgroundColor: '#f9f9f9' }}
                        >
                            {/* Left Border for Priority */}
                            <div
                                className="position-absolute top-0 bottom-0 start-0 rounded-start"
                                style={{
                                    width: '5px',
                                    backgroundColor: activity.priority === 3 ? 'red' : 'blue',
                                }}
                            ></div>

                            {/* Activity Details */}
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div className="d-flex align-items-center">
                                        <strong className="me-2">
                                            <Fingerprint size={16} className="me-1" />
                                            {activity.activity_uid}
                                        </strong>
                                    </div>

                                    <span className="text-muted small">
                                        <CalendarClock size={16} className="me-1" />
                                      {activity.due_date
                                          ? new Date(activity.due_date).toLocaleDateString('en-US', {
                                              year: 'numeric',
                                              month: 'short',
                                              day: 'numeric'
                                          })
                                          : 'No Due Date'}
                                    </span>
                                </div>
                                <p className="mb-1 border rounded p-2 text-capitalize text-info-emphasis">
                                    {activity.description || "No description added"}
                                </p>
                            </div>

                            {/* Priority and Assigned To */}
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <span className="text-muted small">
                                    <User size={16} className="me-1" />
                                    {activity.user?.name || 'No Assignee'}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <span className="text-muted small">
                                    <Users size={16} className="me-1" />
                                    {activity.user_group?.name || 'No Team'}
                                </span>
                                <Badge bg={activity.priority === 3 ? 'danger' : 'primary'}>
                                    {activity.priority === 3 ? (
                                        <Flag size={20} className="me-1" />
                                    ): <AlertOctagon size={20} className="me-1" />}
                                    P{activity.priority}
                                </Badge>
                            </div>
                            <div className="d-flex border rounded p-1 justify-content-between align-items-center mb-2 mt-2">
                                <span className="text-infoÏ mb-1 small"><CircleDot size={16} className="me-1" /> {activity.status?.name}</span>
                                <span className="bg-light text-dark d-flex align-items-center text-lg text-capitalize">
                                            <Activity size={16} className="me-1" />
                                    {activity.activity_type?.name}
                                    </span>
                                <span className="text-muted small"><MapPin size={16} className="me-1" /> {activity.location?.name || 'No location'}</span>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>

        </div>
    );
};
const mapStateToProps = (state: any) => ({
    activities: state.activity.my_activities,
    currentAct: state.activity.activity,
});

export default connect(mapStateToProps)(Activites);
