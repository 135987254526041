import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { toast } from './toastReducer';
import { auth } from './authReducer';
import { asset } from './assetReducer';
import { activity } from './activityReducer';
import { location } from './locationReducer';
import {IRootReducer} from "../models/types";
import {formInputs} from "./formInputReducer";
import {reducer as toastr} from 'react-redux-toastr';
import {loaders} from "./LoaderReducer";
import {setting} from "./settingReducer";

const rootReducer = (history: any) => combineReducers<Partial<IRootReducer>>({
    toast,
    auth,
    setting,
    toastr,
    asset,
    activity,
    location,//TODO refactor to app function, i.e. scanInventory, maybe?
    loaders,
    formInputs,
    router: connectRouter(history),
});

export default rootReducer;
