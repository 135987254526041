import QRScanner from "../components/QRScanner";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { IAsset } from "../models/IAsset";
import { assetProbe } from "../actions/assetActions";
import Asset from "../components/asset";

interface Props {
    dispatch: Function;
    asset?: IAsset;
}

const Inquiry: React.FC<Props> = ({ dispatch, asset }) => {
    const onResult = (data: string) => {
        const urlObj = new URL(data);

        // Use URLSearchParams to get the 'asset' parameter value
        const assetValue = urlObj.searchParams.get("asset");

        if (assetValue) {
            dispatch(assetProbe(assetValue));
        }
    };

    // Scroll to the top whenever asset is not set
    useEffect(() => {
        if (!asset) {
            window.scrollTo(0, 0);
        }
    }, [asset]);

    return (
        <>
            <QRScanner onResult={onResult} scanOnload={true} />
            {asset !== undefined ? <Asset asset={asset} /> : <></>}
        </>
    );
};

const mapStateToProps = (state: any) => ({
    asset: state.asset.asset,
});

export default connect(mapStateToProps)(Inquiry);
