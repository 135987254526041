import React, {useState} from "react";
import {IActivity} from "../models/IAsset";
import {Button, Row, Col, Form} from "react-bootstrap";
import {ScanQrCode, MonitorCog, MapPinPlus, MapPinHouse, ScanLine, CalendarClock} from "lucide-react";
import BottomSheet from "./bottom-sheet";
import {Form as IKForm, Formik, FormikHelpers} from "formik";
import FileUploadField from "./utilities/FileUpload";
import ScannerField from "./utilities/ScannerField";
import UIButton from "./utilities/UIButton";
import * as Yup from "yup";
import {SingleValue} from "react-select";
import {Option} from "../models/types";
import {getMyActivities, modifyActivity} from "../actions/activityActions";
import {connect} from "react-redux";

interface IActivityDetailsProps {
    activity: IActivity;
    onClose: () => void;
    dispatch: Function;
    onStartPerforming: (activity: IActivity) => void;
}
interface FormValues {
    old_location_id?: SingleValue<Option>;
    new_location_id?: SingleValue<Option>;
    asset_id?: SingleValue<Option>;
    remark?: string;
}

const ActivityDetails: React.FC<IActivityDetailsProps> = ({
                                                              activity,
                                                              onClose,
                                                              onStartPerforming,
                                                              dispatch,
                                                          }) => {
    const [isSheetOpen, setIsSheetOpen] = useState(false);
    const [currentItinerary, setCurrentItinerary] = useState("");

    const closeSheet = () => {
        setIsSheetOpen(false);
    };

    const showItinerary = activity.activity_type?.show_scan_asset
        || activity.activity_type?.show_scan_start_location
        || activity.activity_type?.show_scan_end_location

    const showFinishButton = () => {
        if (!showItinerary) return true;
        if (activity.status.name === 'To Do') return false;

        const checks = [
            // Asset scan validation
            !activity.activity_type?.show_scan_asset ||
            (activity.asset_id && activity.asset_scanned),

            // Start location scan validation
            !activity.activity_type?.show_scan_start_location ||
            (activity.old_location_id && activity.start_location_scanned),

            // End location scan validation
            !activity.activity_type?.show_scan_end_location ||
            (activity.new_location_id && activity.finish_location_scanned),
        ];

        return checks.every(Boolean);
    };

    const enableItinerary = (itin: string) => {
        if(activity.status?.name != 'In Execution') return false;

        if (itin === 'scan_asset') {
            return !activity.asset_id || !activity.asset_scanned;
        }
        if (itin === 'scan_start_location') {
            return !activity.old_location_id || !activity.start_location_scanned;
        }
        if (itin === 'scan_end_location') {
            return !activity.new_location_id || !activity.finish_location_scanned;
        }
        return false;
    };

    const handlePickup = () => {
        setCurrentItinerary('pickup')
        dispatch(modifyActivity({
            id: activity.id,
            itinerary: 'pickup'
        }))
    }

    const handleFinish = () => {
        setCurrentItinerary('finish')
        dispatch(modifyActivity({
            id: activity.id,
            itinerary: 'finish'
        }))
    }

    const initialValues: FormValues = {
        new_location_id: {value: '', label: ''},
        old_location_id: {value: '', label: ''},
        asset_id: {value: '', label: ''},
        remark: '',
    };

    const handleItineraryAction = (action: string) => {
        setCurrentItinerary(action);
        setIsSheetOpen(true);

        onStartPerforming(activity); // Ensure state updates
    };

    const handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        const data: any = {
            id: activity.id,
            itinerary: currentItinerary,
            remark: values.remark
        };
        if (values.old_location_id) data.old_location_id = values.old_location_id.value;
        if (values.new_location_id) data.new_location_id = values.new_location_id.value;
        if (values.asset_id) data.asset_id = values.asset_id.value;

        dispatch(modifyActivity(data));
        actions.setSubmitting(false);
        setIsSheetOpen(false)
    };

    const validationSchema = Yup.object({
        // remark: Yup.string().nullable(),
        // old_location_id: Yup.string().nullable(),
        // new_location_id: Yup.string().nullable(),
        // asset_id: Yup.string().nullable()
    });

    return (
        <div className="container py-3">
            {/* Activity Details */}
            <div className="border rounded p-3 mb-3">
                <h5 className="mb-3">
                    Activity Details - <strong>{activity.activity_uid}</strong>
                </h5>

                {/* Description */}
                <p className="mb-2">
                    <strong>Description:</strong>
                    <p className="mb-1 border rounded p-2 text-capitalize text-info-emphasis">
                        {activity.description || "No description added"}
                    </p>
                </p>

                {/* Activity Type */}
                <p className="mb-2">
                    <strong>Type:</strong> {activity.activity_type?.name || "N/A"}
                </p>

                {/* Location */}
                <p className="mb-2">
                    <strong>Location:</strong> {activity.location?.name || "N/A"}
                </p>

                {/* Priority */}
                <p className="mb-2">
                    <strong>Priority:</strong> P{activity.priority || 0}
                </p>

                {/* Assigned User */}
                <p className="mb-2">
                    <strong>Assigned To:</strong> {activity.user?.name || "N/A"}
                </p>

                {/* Due Date */}
                <p className="mb-2">
                    <strong>Due Date:</strong>{" "}
                    <span className="text-muted small">
                                        <CalendarClock size={16} className="me-1" />
                        {activity.due_date
                            ? new Date(activity.due_date).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                            })
                            : 'No Due Date'}
                                    </span>
                </p>

                {/* Status */}
                <p className="mb-2 text-danger">
                    <strong>Status:</strong> {activity.status?.name || "N/A"}
                </p>

                {/* User Group */}
                <p className="mb-2">
                    <strong>User Group:</strong> {activity.user_group?.name || "N/A"}
                </p>
            </div>

            {/* Activity Itinerary */}
            {!!showItinerary && (<div className="border rounded p-3 mb-3">
                <h3>Activity Itinerary</h3>
                {!!activity.activity_type?.show_scan_asset && (<Row className='mb-1'>
                    <Col xs={6} className='pt-2'>Scan asset</Col>
                    <Col xs={3} className='pt-2' style={{fontStyle: 'italic'}}>{activity.asset_scanned ? 'Done' : 'Pending'}</Col>
                    <Col xs={3}>
                        <button className={"btn btn-primary "} disabled={!enableItinerary('scan_asset')}  onClick={() => handleItineraryAction('scan_asset')}>
                            <MonitorCog size={16}/>
                        </button>
                    </Col>
                </Row>)}
                {!!activity.activity_type?.show_scan_start_location && (<Row className='mb-1'>
                    <Col xs={6} className='pt-2'>Scan start location</Col>
                    <Col xs={3} className='pt-2' style={{fontStyle: 'italic'}}>{activity.start_location_scanned ? 'Done' : 'Pending'}</Col>
                    <Col xs={3}>
                        <button className={"btn btn-primary "} disabled={!enableItinerary('scan_start_location')} onClick={() => handleItineraryAction('scan_start_location')}>
                            <ScanQrCode size={16}/>
                        </button>
                    </Col>
                </Row>)}
                {!!activity.activity_type?.show_scan_end_location && (<Row className='mb-1'>
                    <Col xs={6} className='pt-2'>Scan end location</Col>
                    <Col xs={3} className={'pt-2'} style={{fontStyle: 'italic'}}>{activity.finish_location_scanned ? 'Done' : 'Pending'}</Col>
                    <Col xs={3}>
                        <button className={"btn btn-primary "} disabled={!enableItinerary('scan_end_location')} onClick={() => handleItineraryAction('scan_end_location')}>
                            <ScanLine size={16}/>
                        </button>
                    </Col>
                </Row>)}
            </div>)}
            {/* Action Buttons */}
            <Row>
                <Col xs={6}>
                    {showFinishButton() ?
                        (<Button
                            variant="primary"
                            className="w-100"
                            disabled={activity.status?.name === "Finished"}
                            onClick={() => handleFinish()}
                        >
                            Finish
                        </Button>)
                            :
                        (<Button
                            variant="primary"
                            className="w-100"
                            disabled={currentItinerary === "pickup" || activity.status?.name !== "To Do"}
                            onClick={() => handlePickup()}
                        >
                            Start
                        </Button>)}
                </Col>
                <Col xs={6}>
                    <Button
                        variant="outline-secondary"
                        className="w-100"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </Col>
            </Row>

            <BottomSheet isOpen={isSheetOpen} onClose={closeSheet} size="sm">
                <div id='reader'></div>
                <h2 className="text-capitalize">{currentItinerary.replaceAll('_', ' ')}</h2>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched, values, handleSubmit, handleChange, }) => (
                        <IKForm noValidate onSubmit={handleSubmit} style={{overflow: "auto"}}>
                            {currentItinerary === "scan_start_location" && (<ScannerField label='Start Location' scanOnload={false} name='old_location_id'/>)}
                            {currentItinerary === "scan_end_location" && (<ScannerField label='End Location' scanOnload={false} name='new_location_id' />)}
                            {currentItinerary === "scan_asset" && (<ScannerField label='Asset' scanOnload={false} target="asset" name='asset_id' />)}

                            <Form.Label>Remark</Form.Label>
                            <Form.Control
                                as="textarea"
                                type="text"
                                name="remark"
                                value={values.remark}
                                onChange={handleChange}
                                isValid={touched.remark && !errors.remark}
                            />
                            <UIButton variant='primary' type='submit' label='Submit' id='request-sub-btn' className="mt-3"/>
                        </IKForm>
                    )}
                </Formik>
            </BottomSheet>

        </div>
    );
};
const mapStateToProps = (state: any) => ({
    activity: state.activity.activity
})
export default connect(mapStateToProps)(ActivityDetails);
