import React, { useState } from 'react';
import '../assets/styles/bottom-sheet.css';

interface BottomSheetProps {
    children: React.ReactNode;
    isOpen: boolean;
    size?: string;
    onClose: (callback?: Function) => void;
}

const BottomSheet: React.FC<BottomSheetProps> = ({ children, isOpen, onClose, size  }) => {
    const sizeClass = 'open' + (size ? '-' + size : '')
    return (
        <>
            <div className={`bottom-sheet-overlay  ${isOpen ? 'open' : ''}`} onClick={() => onClose()}></div>
            <div className={`bottom-sheet ${isOpen ? sizeClass : ''}`}>
                <div className="bottom-sheet-content">
                    {children}
                    <button style={{display: 'none'}} onClick={() => onClose()} className="close-btn">Close</button>
                </div>
            </div>
        </>
    );
};

export default BottomSheet;
