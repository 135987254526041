import React, {useEffect, useState} from 'react';
import {IActivity, IActivityType, IAsset} from "../models/IAsset";
import {Card, Row, Col, Image, ListGroup, Button} from 'react-bootstrap';
import {Stethoscope, Barcode, MapPin, ClipboardX, ClipboardPen, List, ThumbsUp, Wrench, Sparkles, ArrowRightLeft} from 'lucide-react';
import assetImg from '../assets/images/dispenser.png'
import {getActivityTypes, requestActivity} from "../actions/activityActions";
import {connect} from "react-redux";
import UIButton from "./utilities/UIButton";
import {Utility} from "../utilities/utility";
import * as Yup from 'yup';
import BottomSheet from "./bottom-sheet";
import {Form as IKForm, Formik, FormikHelpers} from "formik";
import FileUploadField from "./utilities/FileUpload";
import { Form } from 'react-bootstrap';
import ScannerField from "./utilities/ScannerField";
import {SingleValue} from "react-select";
import {Option} from "../models/types";

interface Props {
    asset: IAsset;
    activityTypes: IActivityType[];
    dispatch: Function;
}

interface FormValues {
    file: File | null;
    location_id: SingleValue<Option>;
    remark: string;
    priority: number | undefined;
}

const Asset :  React.FC<Props> = ({ asset, activityTypes, dispatch }) => {
    const [isSheetOpen, setIsSheetOpen] = useState(false);
    const [action, setAction] = useState('');

    const closeSheet = () => {
        setIsSheetOpen(false);
    };

    useEffect(function (){
        dispatch(getActivityTypes())
    }, [])

    const initialValues: FormValues = { file: null, location_id: {value: '', label: ''}, remark: '', priority: undefined };

    const handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        handleRequestActivity(action, values)
        actions.setSubmitting(false);
    };
    const validationSchema = Yup.object({
        file: Yup.mixed()
            // @ts-ignore: Ignore TypeScript check for file size validation
            .test('fileSize', 'File too large', (value: File) => value && value.size <= 1024 * 1024) // 1 MB limit
            // @ts-ignore: Ignore TypeScript check for file size validation
            .test('fileFormat', 'Unsupported format', (value: File) => value && ['image/jpeg', 'image/png'].includes(value.type)),
        priority: Yup.number()
            .required('Priority is required')
    });

    const handleRequestActivity = (action: string, data?: FormValues) => {
        switch (action) {
            case 'clean':
                const cleanActivityType = activityTypes.find((activityType) => activityType.name.toLocaleLowerCase() === 'clean')
                dispatch(requestActivity({
                    'asset_id': asset.id,
                    'activity_type_id': cleanActivityType?.id,
                    'remark': data?.remark,
                    'priority': data?.priority,
                    'location_id': data?.location_id?.value,
                    'file': data?.file,
                }, 'clean-btn'))
                break
            case 'repair':
                const repairActivityType = activityTypes.find((activityType) => activityType.name.toLocaleLowerCase() === 'repair')
                dispatch(requestActivity({
                    'asset_id': asset.id,
                    'activity_type_id': repairActivityType?.id,
                    'remark': data?.remark,
                    'priority': data?.priority,
                    'file': data?.file,
                }, 'repair-btn'))
                break
            case 'pickup':
                const moveActivityType = activityTypes.find((activityType) => activityType.name.toLocaleLowerCase() === 'move')
                dispatch(requestActivity({
                    'asset_id': asset.id,
                    'activity_type_id': moveActivityType?.id,
                    'remark': data?.remark,
                    'priority': data?.priority,
                    'location_id': data?.location_id?.value,
                    'file': data?.file,
                }, 'pickup-btn'))
                break
        }
    }

    const handleAction = (action: string) => {
        setAction(action);
        setIsSheetOpen(true)
    }
    let inspectionHasExpired = false;
    const coveredTo = Utility.formatAsDate(asset.last_inspection?.cover_to);
    if(coveredTo) {
        inspectionHasExpired = new Date() > coveredTo;
    }
    return (
        <div className="container mt-4 border-0">
            <Card style={{ width: '100%', padding: '10px' }}>
                <Row style={{borderBottom: 'solid 1px #d2d2d2'}}>
                    <Card.Title className="text-center text-capitalize">{asset.asset_type?.name || asset.supplier?.name || asset.manufacturer?.name}</Card.Title>
                </Row>
                <Row>
                    {/* Details Section */}
                    <Col xs={8}>
                        <Card.Body className="p-0">
                            {/* Details List */}
                            <ListGroup variant="flush" className="border-0">
                                <ListGroup.Item className="d-flex align-items-center text-capitalize py-2 border-0">
                                    <Stethoscope size={16} className="me-2" /> {asset.identifier || asset.supplier?.name || asset.manufacturer?.name || asset.global_gtin?.model}
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center text-capitalize py-2 border-0">
                                    <Barcode size={16} className="me-2" /> {asset.serial_number}
                                    {asset.serial_number ? '' : (<span style={{fontWeight: 'bold', fontSize: '10px', paddingTop: '.25rem'}} className="text-danger">Serial Number Unknown</span>)}
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center text-capitalize py-2 border-0">
                                    <List size={16} className="me-2" /> {asset.manufacturer?.name}
                                    {asset.manufacturer?.name ? '' : (<span style={{fontWeight: 'bold', fontSize: '10px', paddingTop: '.25rem'}} className="text-danger">Manufacturer Unknown</span>)}
                                </ListGroup.Item>
                               <ListGroup.Item className="d-flex align-items-center text-capitalize py-2 border-0">
                                    <List size={16} className="me-2" /> {asset.global_gtin?.model}
                                    {asset.global_gtin?.model ? '' : (<span style={{fontWeight: 'bold', fontSize: '10px', paddingTop: '.25rem'}} className="text-danger">Model Unknown</span>)}
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center text-capitalize py-2 border-0">
                                    <MapPin size={16} className="me-2" />  {asset.location?.name}
                                    {asset.location?.name ? '' : (<span style={{fontWeight: 'bold', fontSize: '10px', paddingTop: '.25rem'}} className="text-danger">Location Unknown</span>)}
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center text-capitalize py-2 border-0">
                                    <ClipboardX size={16} className={"me-2 " + (inspectionHasExpired ? 'text-danger' : '') } /> {Utility.formatAsDate(asset.last_inspection?.executed_at)?.toLocaleDateString('en-US',
                                    {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit'
                                    })}
                                    {asset.last_inspection?.executed_at ? '' : (<span style={{fontWeight: 'bold', fontSize: '10px', paddingTop: '.25rem'}} className="text-danger">Never Inspected</span>)}
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center text-capitalize py-2 border-0">
                                    <ClipboardPen size={16} className={"me-2 " + (inspectionHasExpired ? 'text-danger' : '') } /> {Utility.formatAsDate(asset.last_inspection?.cover_to)?.toLocaleDateString('en-US',
                                    {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit'
                                    })}
                                    {asset.last_inspection?.cover_to ? '' : (<span style={{fontWeight: 'bold', fontSize: '10px', paddingTop: '.25rem'}} className="text-danger">Never Inspected</span>)}
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center text-capitalize py-2 border-0">
                                    <ThumbsUp size={16} className="me-2" /> <span className="text-primary-emphasis">{asset.status?.name}</span>
                                    {asset.status?.name ? '' : (<span style={{fontWeight: 'bold', fontSize: '10px', paddingTop: '.25rem'}} className="text-danger">Status Unknown</span>)}
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Col>

                    {/* Image Section */}
                    <Col xs={4} className="d-flex justify-content-center align-items-center">
                        <Image
                            src={asset.asset_image || assetImg}
                            fluid
                            style={{ maxWidth: '100%', objectFit: 'cover' }}
                        />
                    </Col>
                </Row>

                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item className="py-2 border-0">
                            <UIButton id="clean-btn" Icon={Sparkles} className="w-100" clickFunction={() => handleAction('clean')} label="Clean" />
                        </ListGroup.Item>
                        <ListGroup.Item className="py-2 border-0">
                            <UIButton id="repair-btn" Icon={Wrench} className="w-100" clickFunction={() => handleAction('repair')} label="Repair" />
                        </ListGroup.Item>
                        <ListGroup.Item className="py-2 border-0">
                            <UIButton id="pickup-btn" Icon={ArrowRightLeft} className="w-100" clickFunction={() => handleAction('pickup')} label="Pick Up" />
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Card>

            <BottomSheet isOpen={isSheetOpen} onClose={closeSheet}>
                <h2>Request Repair</h2>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched, values, handleSubmit, handleChange, }) => (
                        <IKForm noValidate onSubmit={handleSubmit} style={{overflow: "auto"}}>
                            <FileUploadField name="file" label="Upload" />

                            <ScannerField label='Location' name='location_id' entity={asset.location}/>

                            <Form.Label>Priority</Form.Label>
                            <Form.Select
                                value={values.priority}
                                onChange={handleChange}
                                name='priority'
                                isValid={touched.priority && !errors.priority}
                            >
                                <option>Select Priority</option>
                                <option value="1">1-Low</option>
                                <option value="2">2-Medium</option>
                                <option value="3">3-High</option>
                            </Form.Select>

                            <Form.Label>Remark</Form.Label>
                            <Form.Control
                                as="textarea"
                                type="text"
                                name="remark"
                                value={values.remark}
                                onChange={handleChange}
                                isValid={touched.remark && !errors.remark}
                            />
                            <UIButton variant='primary' type='submit' label='Submit' id='request-sub-btn' className="mt-3"/>
                        </IKForm>
                    )}
                </Formik>
            </BottomSheet>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    activityTypes: state.asset.activityTypes
});

export default connect(mapStateToProps)(Asset);
