import {IRoute} from "./models/types";

import Public from "./layouts/public";
import Login from "./components/auth/login";
import Protected from "./layouts/protected";
import Dashboard from "./pages/dashboard";
import Inquiry from "./pages/Inquiry";
import TenantNotFound from "./pages/tenant-not-found";
import ScanInventory from "./pages/ScanInventory";
import Activities from "./pages/activity";

const routes: IRoute[] = [
    {
        path: '/',
        as: Public,
        component: Login,
        protected: false
    },
    {
        path: '/login',
        as: Public,
        component: Login,
        protected: false
    },
    {
        path: '/tenant-not-found',
        as: Public,
        component: TenantNotFound,
        protected: false
    },
    {
        path: '/dashboard',
        as: Protected,
        component: Dashboard,
        protected: true
    },
    {
        path: '/inquiry',
        as: Protected,
        component: Inquiry,
        protected: true
    },
    {
        path: '/activity',
        as: Protected,
        component: Activities,
        protected: true
    },
    {
        path: '/scan_inventory',
        as: Protected,
        component: ScanInventory,
        protected: true
    },
];

export default routes;
