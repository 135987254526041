import React from "react";
import QRScanner from "../components/QRScanner";
import {
    // locationProbe,
    locationProbe2
} from "../actions/locationActions";
import { ILocation } from "../models/ILocation";
import { ICard } from "../models/ICard";
import { connect } from "react-redux";
// import * as actionTypes from "../actionsTypes";
import { Button } from "react-bootstrap";
import ApiClient from "../utilities/http";
import { Endpoints } from "../utilities/endpoints";
import { toastError, toastSuccess } from "../actions/formInputAction";
import InventoryCountModal from "../components/count-inventory-modal";
import CountedCardsTable from "../components/counted-cards-table";
import { submitInventoryCounts2 } from "../actions/inventoryActions";

// interface Props {
//     dispatch: Function;
//     location?: ILocation;
//     // card?: ICard;
// }
interface Props { }//FIXME when I am quite sure on how to use dispatch()

enum WorkflowStage {
    SCAN_LOCATION = 1,
    LOCATION_ERROR,
    SCAN_CARD,
    CARD_ERROR,
    CARD_SCANNED,
    DONE,
}

// const ScanInventory: React.FC<Props> = ({ dispatch, location }) => {
const ScanInventory: React.FC<Props> = () => { //FIXME when I am quite sure on how to use dispatch()
    const [currentCard, setCurrentCard] = React.useState<ICard>();
    const [showModal, setShowModal] = React.useState(false);
    const [stage, setStage] = React.useState<WorkflowStage>(WorkflowStage.SCAN_LOCATION);
    const [countedCards, setCountedCards] = React.useState<ICard[]>([]);
    // const [cardsToScan, setCardsToScan] = React.useState<number>(-1);
    const [errors, setErrors] = React.useState<string[]>([]);
    const [currentLocation, setCurrentLocation] = React.useState<ILocation>();
    const [sendButtonDisabled, setSendButtonDisabled] = React.useState<boolean>(false);

    React.useEffect(() => {
        // if (location === undefined) {
        //     setStage(WorkflowStage.SCAN_LOCATION);
        //     return;
        // }
        manageWorkflow();
    }, [
        // location,
        currentLocation, stage]);

    function manageWorkflow() {
        if (currentLocation !== undefined) {
            if (currentLocation?.cards === undefined) {
                setStage(WorkflowStage.LOCATION_ERROR);
                setErrors(current => [...current, 'this location has no bins, so is not suitable for inventory scanning']);
                // setCardsToScan(-1);
                return;
            }
        }

        switch (stage) {
            case WorkflowStage.SCAN_LOCATION:
                setCurrentLocation(undefined);
                break;
            case WorkflowStage.SCAN_CARD:
                // setCurrentLocation(location);
                break;
        }
    }

    function handleTableClick(evt: any) {
        // debugger;
        let card = getCardById(countedCards, evt.currentTarget.id);
        if (card != null) {
            setCurrentCard(card);
            setShowModal(true);
        }
    }

    /**
      *
      * update countedInventory in-place
      * @param id
      * @param newCount
      * @returns
      */
    function updateCountedCards(id: string, newCount: number) {
        console.log(`updateCountedCards, id = ${id}, newCount = ${newCount}`);
        setCountedCards(prevCards =>
            prevCards.map(card => {
                if (card.id === id) {
                    return { ...card, countedInventory: newCount }
                }
                return card;
            }));
    }

    function ScanInventoryInstructions() {//TODO merge with manageworkflow
        let msg = 'Scan locatie';
        switch (stage) {
            case WorkflowStage.SCAN_LOCATION:
                msg = "Scan een locatie";
                break;
            case WorkflowStage.LOCATION_ERROR:
                msg = "Dat is geen geldige locatie, Probeer het nog eens";
                break;
            case WorkflowStage.SCAN_CARD:
                msg = "Scan een card";
                break;
            case WorkflowStage.CARD_SCANNED:
                msg = "Voer het aantal artikelen in";
                break;
            case WorkflowStage.CARD_ERROR:
                msg = "Dat is geen geldige card. Probeer het nog eens";
                break;
            case WorkflowStage.DONE:
                return null;
            default:
                msg = "Wat doe je nou?";
                break;
        }
        return (
            <>
                <p className="mx-auto">
                    {msg}
                </p>
            </>
        )
    }

    function getCardById(src: ICard[], id: string): ICard | null {
        if (currentLocation === undefined) {
            return null;
        }
        if (currentLocation.cards === undefined) {
            return null;
        }
        let card = src.find(card => card.id === id);
        if (card !== undefined) {
            return card;
        }
        return null;
    }

    const handleScannedCode = (data: string) => {
        console.log(data);
        if(/^(https?:\/\/)?([^\s$.?#].[^\s]*)$/i.test(data))
            data = data.split('/').pop() || '';
        /*        let parts = data.split(':');
        let scannedNamespace = '';
        let scannedCode = data;
        if (parts.length !== 2) {
            //     //then we do not have a type:uuid scancode
            //     return; //error? different handling?
        }
        //do something with scannedNameSpace
        switch (parts[0].toLowerCase()) {
            case 'card':
            case 'location':
                scannedNamespace = parts[0].toLowerCase();
                scannedCode = parts[1];
                break;
            default:
                break;
        }*/

        switch (stage) {
            case WorkflowStage.SCAN_LOCATION:
                // dispatch(locationProbe(data));
                locationProbe2(data)
                    .then(res => {
                        let probedLocation = res as ILocation;
                        if (probedLocation.cards === undefined) {
                            console.log('331.handleScannedCode, setting stage to location_error because no cards attached');
                            setStage(WorkflowStage.LOCATION_ERROR);
                            setErrors(current => [...current, 'this location has no bins, so is not suitable for inventory scanning']);
                        } else {
                            setCurrentLocation(probedLocation);
                            setErrors([]);
                            // setCardsToScan(probedLocation.cards.length);
                            setStage(WorkflowStage.SCAN_CARD);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        // debugger;
                    })
                break;
            case WorkflowStage.SCAN_CARD:
                if (currentLocation !== undefined) {
                    if (currentLocation.cards !== undefined) {
                        let card = null;
                        // if (scannedNamespace == '') {
                            card = currentLocation.cards.find(card => card.rfid === data);
                        // } else {
                        //     card = currentLocation.cards.find(card => card.rfid === scannedCode);
                        // }
                        if (card !== undefined) {
                            // dispatch({
                            //     type: actionTypes.CURRENT_CARD,
                            //     payload: { ...card }
                            // })
                            setShowModal(true);
                            setCurrentCard(card);
                        }
                    }
                }
                break;
            case WorkflowStage.CARD_SCANNED:
                break;
            case WorkflowStage.CARD_ERROR:
                break;
            default:
                break;
        }

        /*
        //FIXME To be reactivated if and when we fix the scancode format to be <TYPE>:uuid
         if (parts[0].toLowerCase() === 'location') {
              // console.log('284.dispatching locationProbe')
              // dispatch(locationProbe(data));
              locationProbe2(data)
                  .then(res => {
                      // debugger;
                  })
                  .catch(err => {
                      // debugger;
                  })
              //TODO on full data? or on reduced component, e.g. location id?
          } */
    }

    function LocationInformation() {
        // console.log('LocationInformation: currentLocation =', currentLocation);

        if (currentLocation === undefined) {
            return null;
        }
        // console.log(`LocationInformation, stage =`, stage);

        if (currentLocation.cards !== undefined) {
            let cardsToDo = currentLocation.cards.length - countedCards.length;
            let msg = `Nog ${cardsToDo} cards`;
            if (cardsToDo === 0) {
                msg = 'Klaar om te verzenden';
                //TODO this throws a warning and MUST be fixed!!
                setStage(WorkflowStage.DONE);//FIXME ugly side-effect, we MUST take this out somehow
            }
            return (
                <>
                    <p>Locatie: {currentLocation.name}</p>
                    <p>{msg}</p>
                </>
            )
        }
        return null;
    }

    function handleModalSubmit(values: any) {
        // console.log('values:', values);
        // debugger;
        if (currentLocation === undefined) {
            console.error('handleModalSubmit, currentLocation is undefined');
            return;
        }
        if (currentLocation.cards === undefined) {
            console.error('handleModalSubmit, currentLocation.cards is undefined');
            return;
        }

        let idx = countedCards.findIndex(elem => elem.id === values.card.id);
        if (idx === -1) {
            setCountedCards(current => [...current, { ...values.card, countedInventory: values.quantity }]);
            // setCardsToScan(cardsToScan - 1);
        } else {
            updateCountedCards(values.card.id, values.quantity);
        }
        // setCardsToScan(currentLocation.cards.length - countedCards.length);
        setCurrentCard(undefined);//TODO is this necessary?
        setShowModal(false);
        manageWorkflow();
    }

    return <>
        <ScanInventoryInstructions />
        <LocationInformation />

        {errors.length !== 0 ?
            <>
                <p>ERROR:</p>
                <ul>
                    {errors.map(err => `<li>${err}</li>`)}
                </ul>
            </>
            : null}
        {stage !== WorkflowStage.DONE ?
            <QRScanner onResult={handleScannedCode} /> :
            null
        }
        {currentCard === undefined ? null :
            <InventoryCountModal card={currentCard} show={showModal} submitHandler={handleModalSubmit} cancelHandler={() => {
                setShowModal(false);
            }} />
        }

        {stage === WorkflowStage.DONE ?
            <Button disabled={sendButtonDisabled} onClick={() => {
                //TODO Formik has an isSubmitting flag built-in, maybe we can play with that
                if (currentLocation !== undefined) {
                    // dispatch(submitInventoryCounts({ location, countedCards }));
                    setSendButtonDisabled(true);
                    submitInventoryCounts2(currentLocation, countedCards)
                        .then(res => {
                            toastSuccess('yay success', 'yay again');
                            setCountedCards([]);
                            setCurrentCard(undefined);
                            setStage(WorkflowStage.SCAN_LOCATION);//effectively restart
                            setSendButtonDisabled(false);
                        })
                        .catch(err => {
                            setSendButtonDisabled(false);

                            toastError(err, 'ERROR');
                        });
                }
            }}>Verzend</Button>
            : null}

        <CountedCardsTable cards={countedCards} clickHandler={handleTableClick} />
    </>
}

// short form:
const mapStateToProps = (state: any) => ({
    location: state.location.location
});

//long form
// function mapStateToProps(state: any) {
//     // debugger;
//     if (state.location !== undefined) {
//         // console.log('411.mapStateToProps: location is undefined')
//     } else {
//         // console.log('413.mapStateToProps: state has a location!!')
//     }
//     return {
//         location: state.location.location
//     };
// }

// export default ScanInventory;
export default connect(mapStateToProps)(ScanInventory);
