import React, {useEffect, useState} from 'react';
import '../assets/styles/App.css';
import {Navbar, Nav, Container, Button, Modal} from 'react-bootstrap';
import {Link, Outlet, useNavigate, useNavigation} from "react-router-dom";
import {getLogoURL, logout} from "../actions/authActions";
import {connect} from "react-redux";
import {IUser} from "../models/IUser";
import ReduxToastr from "react-redux-toastr";
import {Bell, Home, Menu, QrCode, Settings, User} from "lucide-react";
import {isString} from "lodash";

interface Props {
    dispatch: Function;
    user: IUser;
    logoUrl?: string
}
const Protected :  React.FC<Props> = ({ dispatch, logoUrl }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleLogout = () => dispatch(logout());
    const navigate = useNavigate();
    const menuItems = [
        { name: 'Home', icon: Home, target: '/dashboard'},
        // { name: 'Settings', icon: Settings, target: '/setting'},
        { name: 'Logout', icon: User, target: handleLogout },
    ];
    const handleMenuClick = (target: string|Function) => {
        if(isString(target)){
            navigate(target);
            return;
        }
        target();
    }

    const handleQrCodeClick = () => {
        console.log('QR Code icon clicked');
    };

    useEffect(() => {
        dispatch(getLogoURL())
    }, [])
    return (
        <div className="dashboard-app">
            <Navbar bg="primary" variant="dark" className="shadow-md p-1">
                <Container fluid className="d-flex justify-content-between align-items-center">
                    <Navbar.Brand className="fw-bold" onClick={() => navigate('/dashboard')}><img src={logoUrl} alt="Platform Logo" style={{width: '150px'}}/></Navbar.Brand>
                    <div className="d-flex align-items-center">
                        <Button variant="link" className="p-1 text-white" onClick={handleQrCodeClick}>
                            <Bell size={24} />
                        </Button>
                        <Button variant="link" className="p-1 text-white" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <Menu size={24} />
                        </Button>
                    </div>
                </Container>
            </Navbar>

            <Modal show={isMenuOpen} onHide={() => setIsMenuOpen(false)} animation={false} className="menu-modal">
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body className="p-0">
                    <Nav className="flex-column">
                        {menuItems.map((item) => (
                            <Nav.Link key={item.name} onClick={() => handleMenuClick(item.target)} className="d-flex align-items-center p-2">
                                <item.icon className="me-3 text-primary" size={20} />
                                {item.name}
                            </Nav.Link>
                        ))}
                    </Nav>
                </Modal.Body>
            </Modal>

            {/* Body Content */}
            <main className="d-flex flex-column min-vh-100 bg-light position-relative overflow-hidden p-2">
                <Outlet></Outlet>
            </main>

            <ReduxToastr
                timeOut={7000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                closeOnToastrClick/>
            {/* Footer */}
            <footer className="bg-light py-3 mt-auto shadow-inner text-center">
                <small>&copy; 2024 Powered by AllTrace</small>
            </footer>
        </div>

    );
}

const mapStateToProps = (state: any) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.currentUser,
    logoUrl: state.setting.logoURL
});

export default connect(mapStateToProps)(Protected);
