export const production = {
    envName: 'production',
    appName: 'AllTrace',
    debug: false,
    bypassAuth: false,
    clientIdentifier: "z00WpXQTcyMJGAKpMpZs9MdrhpFOQY0K9km4fv3h7mDnbSDa4VuhWYUDuUigA7Zy",
    // api: 'https://{tenant}.alltrace.app/api/',
    api: `https://{tenant}.${process.env.REACT_APP_TARGET_DOMAIN}/api/`,
    // url: 'https://m.alltrace.app/',
    url: `https://m.${process.env.REACT_APP_TARGET_DOMAIN}/`,
};
