export const RECEIVED_MY_ACTIVITIES = 'RECEIVED_MY_ACTIVITIES';
export const RECEIVED_ACTIVITIES = 'RECEIVED_ACTIVITIES';
export const SET_CURRENT_ACTIVITY = 'SET_CURRENT_ACTIVITY';
export const UNSET_CURRENT_ACTIVITY = 'UNSET_CURRENT_ACTIVITY';
export const RECEIVED_MY_ACTIVITY_COUNT = 'RECEIVED_ACTIVITY_COUNT';
export const ADD_ERROR = 'ADD_ERROR';
export const ADD_INFO = 'ADD_INFO';
export const ADD_TOAST = 'ADD_TOAST';
export const APP_CHECK_LOGIN_END = 'APP_CHECK_LOGIN_ENDS';
export const APP_CHECK_LOGIN_START = 'APP_CHECK_LOGIN_STARTS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_INFO = 'CLEAR_INFO';
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const CONFIRM_DELETE = 'CONFIRM_DELETE';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_ACTIVITY_TYPES = 'GET_ACTIVITY_TYPES';
export const HIDE_MODAL = 'HIDE_MODAL';
export const RECEIVE_LOGO_URL = "RECEIVE_LOGO_URL";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const PARALLEL_LOAD_ENDS = 'PARALLEL_LOAD_ENDS';
export const PARALLEL_LOAD_STARTS = 'PARALLEL_LOAD_STARTS';
export const PROBE_ASSET = 'PROBE_ASSET';
export const UNSET_PROBED_ASSET = 'UNSET_PROBE_ASSET';
export const RECEIVE_ACTOR = 'RECEIVE_ACTOR';
export const RECEIVE_ITEMS = 'RECEIVE_ITEMS';
export const RECEIVE_NOTIFICATION = "RECEIVE_NOTIFICATION";
export const RECEIVE_PREDEFINED_STATUS = "RECEIVE_PREDEFINED_STATUS";
export const RECEIVE_SEARCH = "RECEIVE_SEARCH";
export const RECEIVE_USER_STATUS = "RECEIVE_USER_STATUS";
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const REQUEST_ACTIVITY_CREATION = 'REQUEST_ACTIVITY_CREATION';
export const REQUEST_ENDS = 'REQUEST_ENDS';
export const REQUEST_STARTS = 'REQUEST_STARTS';
export const SET_TOAST = 'SET_TOAST';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SUBMIT_ENDS = 'SUBMIT_ENDS';
export const SUBMIT_STARTS = 'SUBMIT_STARTS';
export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
export const PROBE_LOCATION = 'PROBE_LOCATION';
export const PROBE_CARD = 'PROBE_CARD';

//when the inventory has been counted
export const UPDATE_CARD = 'UPDATE_CARD';
export const CURRENT_CARD = 'CURRENT_CARD';
// export const SEND_INVENTORY = 'SEND_INVENTORY';
//reset the location
export const RESET_LOCATION = 'RESET_LOCATION';

